// Modal.js
import React, { useState } from "react";
import "./Modal.css"; // Create a CSS file for styling the modal
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const Modal = ({ isOpen, onClose, userMessage, onSuccess }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const maxLengthMessage = 17; //for displaying message
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  if (!isOpen) {
    return null;
  }

  const productionURL = "https://iamlibin-backend.onrender.com/api/message";
  const devURL = "http://192.168.56.1:5009/api/message";

  const handleSaveEvent = async () => {
    //check if saving is in progress. if true return without escecuting further
    if (isSaving) return;
    const message = { message: userMessage, name, email, phone };
    //name,email and phone key name are same. but for user messages in schema key name is "message"
    console.log(name, email, phone);
    //checking for blank fields
    if (
      !name ||
      name.trim() == 0 ||
      !email ||
      email.trim() == 0 ||
      !phone ||
      phone.trim() == 0
    ) {
      setIsValid(false);
      console.log("blank fields");
      setTimeout(() => {
        setIsValid(true);
      }, 500);
      return;
    }
    if (isValid) {
      setIsSaving(true); //saving mode is turned on so that there wont be dup API call
      try {
        // Make a POST request to the backend API
        const response = await fetch(productionURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(message),
        });

        if (response.ok) {
          // if event is saved to DB, reset all input fields and turn of savings mode
          //is saved is set to true to conditionally render "message"
          console.log("Event saved successfully!");
          setIsSaving(false);
          setIsSaved(true);
          setName("");
          setEmail("");
          setPhone("");
        } else {
          // Handle error: show an error message in console
          //seperate error handling for scenarios like internet issues pending. >>>> NEED UPDATE
          console.error("Failed to save event");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        //onSuccess is called so that props will pass to parent component to close the modal
        //setTime out >> sucess message will be readable

        setTimeout(() => {
          //saved is set false to nullify the effect or else "send success" will keep on rendring
          setIsSaved(false);
          onSuccess();
        }, 700);
      }
    } else {
      setIsValid(false);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="close-button">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        {isSaving ? (
          //terinary operation - to render a "message when savings mode is on"
          <>
            <p>Sending.... please wait</p>
          </>
        ) : isSaved ? (
          <>
            <p>Message Send. Thank you</p>
          </>
        ) : (
          <>
            <p className="model-element">
              Hello, Thank You for reaching out. please let me know your name
              and contact details so that i can get back to you.
            </p>
            <div className="model-element">
              <label htmlFor="name">Name:</label>
              <input
                autoComplete="off"
                className="message-input"
                type="text"
                id="name"
                value={name}
                onChange={handleNameChange}
              />
            </div>
            <div className="model-element">
              <label htmlFor="email">Email:</label>
              <input
                autoComplete="off"
                className="message-input"
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <div className="model-element">
              <label htmlFor="phone">Phone:</label>
              <input
                autoComplete="off"
                className="message-input"
                type="number"
                id="phone"
                value={phone}
                onChange={handlePhoneChange}
              />
            </div>
            {/* user message from home screen passed as prop will be shown upto 15 - 20 charectors */}
            <p className="model-element">
              Your Message :
              {userMessage.length > maxLengthMessage
                ? userMessage.substring(0, maxLengthMessage) + "....."
                : userMessage}
            </p>

            <div className="model-element">
              <button
                style={!isValid ? { backgroundColor: "red" } : {}}
                className="home-nav-button"
                onClick={handleSaveEvent}
              >
                Send Message
              </button>
              <button className="home-nav-button" onClick={onClose}>
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Modal;
