import React, { useState } from "react";
import "./introduction.css";
import profilePic from "../Public/profilepic.jpeg";
import mernLogo from "../Public/mern.png";
import reactNativeLogo from "../Public/reactnative.png";
import iosLogo from "../Public/ios.png";
import androidLogo from "../Public/android.png";
import awsLogo from "../Public/aws.png";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import MessageModal from "./MessageModal"; // Import the Modal component

function Introduction() {
  const [isValid, setIsValid] = useState(true);
  const [messageInput, setMessageInput] = useState("");
  // Update form data when input values change
  const [isModalOpen, setModalOpen] = useState(false);
  //for overlay for collecting name, email and phone
  const [placeHolder, setPlaceHolder] = useState("Send me a message....");
  //for overlay for collecting name, email and phone
  const errorMessage = "please type something to send message";

  if (isValid == false) {
    setTimeout(() => {
      setPlaceHolder("Send me a message....");
      setMessageInput("");
      setIsValid(true);
    }, "800");
  }

  const messageHandler = () => {
    if (messageInput.trim() == 0 || messageInput.trim() == errorMessage) {
      console.log("messange handler success");
      setPlaceHolder(errorMessage);
      setMessageInput(errorMessage);
      setIsValid(false);
    } else {
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const onSaveSuccess = () => {
    setModalOpen(false);
    setMessageInput("");
    console.log("test passed");
  };

  return (
    <div className="conatainer-main">
      <div className="container-sub">
        <div className="intro-container">
          <h1>Hello,</h1>
          <h2>I am Libin Geevarghese,</h2>
          <p className="label-stacks">
            Full Stack Developer || MobileDeveloper
          </p>

          <div className="logos-stacks">
            <img
              id="mernLogo"
              className="logos"
              src={mernLogo}
              alt="mern"
            ></img>
            <span>
              <img id="iosLogo" className="logos" src={iosLogo} alt="ios"></img>
              <img
                id="androidLogo"
                className="logos"
                src={androidLogo}
                alt="android"
              ></img>
              <img
                id="reactNativeLogo"
                className="logos"
                src={reactNativeLogo}
                alt="react native"
              ></img>
              <img id="awsLogo" className="logos" src={awsLogo} alt="aws"></img>
            </span>
          </div>
        </div>
        <div className="photo-container">
          <img id="profilePic" src={profilePic} alt="profile" />
        </div>
      </div>

      <div className="messageContainer">
        <textarea
          style={{ backgroundColor: isValid ? "#d7d7d7" : "#ed4845" }}
          id="message-textarea"
          placeholder={placeHolder}
          // name="message" // Add name attribute
          value={messageInput} // Bind value to state
          onChange={(e) => setMessageInput(e.target.value)} // Add onChange handler
        ></textarea>
        <p className="muIcon">
          <IconButton onClick={messageHandler}>
            <SendIcon />
          </IconButton>

          {/* handleSaveEvent */}
        </p>
      </div>
      <MessageModal
        userMessage={messageInput}
        isOpen={isModalOpen}
        onClose={closeModal}
        onSuccess={onSaveSuccess}
      />

      <div className="container-bottom">
        <Link to="about">
          <button className="home-nav-button">About Me</button>
        </Link>
        <Link to="#">
          <button className="home-nav-button">Admin Access</button>
        </Link>
      </div>
    </div>
  );
}

export default Introduction;
